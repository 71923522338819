import React, { useEffect, useState } from "react";
import { useHeaderStyles } from "@/static/stylesheets/molecules";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { Avatar } from "@mui/material";

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from "classnames";
import { useNavigate } from "react-router-dom";



const Header = () => {
  const classes = useHeaderStyles();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let name = localStorage.getItem('wholeseller_contact_per') as string;
    setName(name);
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <span>
          <SearchIcon />
        </span>
        <input type={"text"} placeholder={"Search"} />
      </div>
      <div className={classes.menuContainer}>
        <span className={"notification-button"}>
          <NotificationsNoneOutlinedIcon />
        </span>

        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="">
              <span className={"profile-button"}>
                <Avatar
                  className={"avatar"}
                // src={
                //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"
                // }
                />
                <span className={"title"}>{name}</span>
                <span className={"arrow-dropdown"}>
                  <ArrowDropDownOutlinedIcon aria-hidden="true" />
                </span>
              </span>
            </Menu.Button>
          </div>

          <Transition
         
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (

                    <button
                      onClick={() => {
                        navigate("/profile")
                      }}
                      type="submit"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm'
                      )}
                    >
                      My Profile
                    </button>

                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        localStorage.clear();
                        navigate("/login")
                      }}
                      type="submit"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm'
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

      </div>






    </div>
  );
};

export { Header };
